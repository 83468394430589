<script setup>
import { usePage } from "@inertiajs/vue3";
import LuminFireLogo from "./LuminFireLogo.vue";
import { usePolicies } from "@/Composables/Permissions.js";

const { can } = usePolicies();

var props = defineProps({
    class: {
        type: String,
        default: "w-48",
    },
    internalClass: {
        type: String,
        default: "w-96",
    },
    playing: {
        type: Number,
        default: null,
    },
    withBrilliantPortalBranding: {
        type: Boolean,
        default: false,
    },
});

const seeAudio = usePage().props.appPermissions.seeEmployeeResources;
const audioFiles = seeAudio ? usePage().props.logo.clips : [];

var players = [];

audioFiles.forEach((audioFile) => {
    players.push({
        isPlaying: false,
        audio: new Audio(audioFile),
    });
});

const playRandomAudio = () => {
    var playing = players.filter((player) => player.isPlaying);

    if (playing.length > 0) {
        playing.forEach((player) => {
            player.audio.pause();
            player.audio.currentTime = 0;
            player.isPlaying = false;
        });
    } else {
        const randomIndex = Math.floor(Math.random() * players.length);
        players[randomIndex].isPlaying = true;
        players[randomIndex].audio.play();
    }
};
</script>

<template>
    <div v-if="can('seeEmployeeResources')" class="block cursor-pointer" :class="class" @click="playRandomAudio">
        <img src="/img/opstimus-prime-logo.png" alt="Opstimus Prime Logo" />

        <audio ref="audioPlayer" preload="metadata" />
    </div>
    <div v-else class="dark:text-white font-bold">
        <LuminFireLogo class="mt-2" :class="internalClass" />
        <span v-if="withBrilliantPortalBranding" class="ml-6 -top-3 relative">BrilliantPortal</span>
    </div>
</template>
